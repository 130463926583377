var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            { attrs: { title: "收款结果" } },
            [
              _c("van-cell", {
                attrs: {
                  title: "应收总额（元）",
                  value: _vm.utils.moneyFormat(
                    _vm.collectionAmountSum.toFixed(2)
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "待收金额（元）",
                  value: _vm.utils.moneyFormat(
                    (
                      ((_vm.collectionAmountSum || 0) * 1000 - _vm.amountPaid) /
                      1000
                    ).toFixed(2)
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "已收金额（元）",
                  value: _vm.utils.moneyFormat(
                    (_vm.amountPaid / 1000).toFixed(2)
                  )
                }
              }),
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c("van-cell", [
                    _c(
                      "div",
                      { staticClass: "goods-table" },
                      _vm._l(_vm.appDownList, function(item, idx) {
                        return _c(
                          "table",
                          {
                            key: item.id,
                            staticClass: "inner-goods-table",
                            attrs: { border: "1" }
                          },
                          [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "header-th",
                                  attrs: { colspan: "2" }
                                },
                                [_c("span", [_vm._v(_vm._s(idx + 1))])]
                              )
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("收款日期")]),
                              _c("td", [_vm._v(_vm._s(item.collectionDate))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("付款方式")]),
                              _c("td", [_vm._v(_vm._s(item.collectionType))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("收款金额")]),
                              _c("td", [_vm._v(_vm._s(item.collectionAmount))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("付款方")]),
                              _c("td", [
                                _vm._v(_vm._s(item.beneficiaryOrgName))
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("付款方账号")]),
                              _c("td", [
                                _vm._v(_vm._s(item.accountsReceivable))
                              ])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("备注")]),
                              _c("td", [_vm._v(_vm._s(item.remarks))])
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("附件")]),
                              _c(
                                "td",
                                [
                                  _c("van-uploader", {
                                    attrs: {
                                      "show-upload": false,
                                      deletable: false,
                                      disabled: ""
                                    },
                                    on: { "click-preview": _vm.preview },
                                    model: {
                                      value: item.fileDetailInfoVoList,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "fileDetailInfoVoList",
                                          $$v
                                        )
                                      },
                                      expression: "item.fileDetailInfoVoList"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("状态")]),
                              _c("td", [
                                _vm._v(" " + _vm._s(item.resultStatus) + " "),
                                item.resultStatus == 0
                                  ? _c("p", [_vm._v("未提交")])
                                  : _vm._e(),
                                item.resultStatus == 1
                                  ? _c("p", [_vm._v("审核中")])
                                  : _vm._e(),
                                item.resultStatus == 2
                                  ? _c("p", [_vm._v("上级退回")])
                                  : _c("p", [_vm._v("审核通过")])
                              ])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }