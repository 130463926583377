<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-cell-group title="收款结果">
        <van-cell title="应收总额（元）" :value="utils.moneyFormat(collectionAmountSum.toFixed(2))" />
        <van-cell title="待收金额（元）" :value="utils.moneyFormat(((((collectionAmountSum || 0) * 1000) - amountPaid) / 1000).toFixed(2))" />
        <van-cell title="已收金额（元）" :value="utils.moneyFormat((amountPaid / 1000).toFixed(2))" />
        <div class="van-common-detail">
          <van-cell>
            <div class="goods-table">
              <table v-for="(item,idx) in appDownList" :key="item.id" class="inner-goods-table" border="1">
                <tr>
                  <th colspan="2" class="header-th">
                    <span>{{ idx+1 }}</span>
                  </th>
                </tr>
                <tr>
                  <td>收款日期</td><td>{{ item.collectionDate }}</td>
                </tr>
                <tr>
                  <td>付款方式</td><td>{{ item.collectionType }}</td>
                </tr>
                <tr>
                  <td>收款金额</td><td>{{ item.collectionAmount }}</td>
                </tr>
                <tr>
                  <td>付款方</td><td>{{ item.beneficiaryOrgName }}</td>
                </tr>
                <tr>
                  <td>付款方账号</td><td>{{ item.accountsReceivable }}</td>
                </tr>
                <tr>
                  <td>备注</td><td>{{ item.remarks }}</td>
                </tr>
                <tr>
                  <td>附件</td>
                  <td>
                    <van-uploader v-model="item.fileDetailInfoVoList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
                  </td>
                </tr>
                <tr>
                  <td>状态</td>
                  <td>
                    {{ item.resultStatus }}
                    <p v-if="item.resultStatus == 0">未提交</p>
                    <p v-if="item.resultStatus == 1">审核中</p>
                    <p v-if="item.resultStatus == 2">上级退回</p>
                    <p v-else>审核通过</p>
                  </td>
                </tr>
              </table>
            </div>
          </van-cell>
        </div>
      </van-cell-group>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
// import fileList from '@/components/file-list'
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      inWarehousingDetail: {},
      quotaDetail: {},
      qualificationList: [],
      businessLicenseList: [],
      fileBusinessSnImgsList: [],
      appDownList: [],
      collectionAmountSum: 0,
      amountPaid: 0
    }
  },
  created () {
    this.getInfoByCollectionId()
  },
  methods: {
    // 处理文件数组
    getFileName (fileUrl) {
      const splitList = fileUrl ? fileUrl.split('/') : []
      return splitList[splitList.length - 1] || ''
    },
    // 根据申请付款UUID查询未提交除外付款结果
    getInfoByCollectionId () {
      this.api.task.outWarehouse.getInfoByCollectionId(this.$route.query.collectionId).then(res => {
        this.appDownList = res.data.value || []
        this.collectionAmountSum = this.appDownList.length !== 0 ? this.appDownList[0].collectionAmountSum || 0 : 0
        this.appDownList.forEach(item => {
          item.collectionDate = (item.collectionDate || '').split('T')[0]
          if (item.resultStatus === 3) {
            this.amountPaid = this.amountPaid + ((item.collectionAmount || 0) * 1000)
          }
        })
      })
    },
    preview (file, detail) {
      if (file.fileType) {
        const fileTypeArr = file.fileType.split('/')
        if (fileTypeArr[0] !== 'image') {
          window.open(file.url)
        }
      }
    }
  }
}
</script>

<style scoped>
  .certificate-photo >>> .van-uploader__wrapper {
    display: inline-flex !important;
    flex-wrap: inherit !important;
  }
  .van-common-detail >>> .van-tabs .van-tabs__wrap .van-tabs__nav:first-child .van-tab{
    padding: 0 !important;
  }
</style>
